export default {
	'e003': {
		name: '大发',
		img: require ('../assets/images/e003.png'),
		url: 'https://bw.calongheiyou.com/dafa22222.mobileconfig'
	},
	'e010': {
		name: 'bet365',
		img: require ('../assets/images/e010.png'),
		url: 'https://bw.calongheiyou.com/bbbb365.mobileconfig'
	},
	'e011': {
		name: '美高美',
		img: require ('../assets/images/e011.png'),
		url: 'https://bw.calongheiyou.com/mgm22222.mobileconfig'
	},
	'e046': {
		name: '伟易博',
		img: require ('../assets/images/e046.png'),
		url: 'https://jxh.aligatao.com/vb.mobileconfig'
	},
	'e069': {
		name: '必赢亚洲',
		img: require ('../assets/images/e069.png'),
		url: 'https://bw.calongheiyou.com/bbyz.mobileconfig'
	},
	'm102': {
		name: '天亿',
		img: require ('../assets/images/m102.png'),
		url: 'https://bw.calongheiyou.com/newtyy.mobileconfig'
	},
  'm107': {
		name: '葡京99',
		img: require ('../assets/images/m107.png'),
		url: 'https://jxh.aligatao.com/pujing99.mobileconfig'
	},
  'm109': {
		name: '星辉体育',
		img: require ('../assets/images/m109.png'),
		url: 'https://jxh.aligatao.com/xinghui.mobileconfig',
    bg: require ('../assets/images/banner-m109.png')
	},
}
