<template>
  <div :class="{'m109': code === 'm109'}">
    <div :class="['banner', 'banner-txt-wrap']" >
      <div class="main-logo">
        <img :src="ob.img" />
      </div>
      <h3>欢迎下载</h3>
      <h2>{{ob.name}}</h2>
    </div>
    <!--<span class="reminder">下载新 APP 后，请删除旧的，<br> 请放心这不会给您造成任何影响！</span>-->
    <p class="downTxt">版本要求：iOS 9.0 以上</p>
    <div class="btn-box">
      <a id="btn" :href="ob.url" class="btn-download" @click="isShow = !isShow">立即下载</a>
      <div class="down-reminder" v-show="isShow">请到桌面查看安装进程或立即安装</div>
    </div>
    <div class="btn-box" v-show="isShow">
<!--      <a-->
<!--        href="https://bw.calongheiyou.com/open.mobileprovision"-->
<!--        class="btn-download"-->
<!--      >立即安装</a>-->
      <div class="down-reminder">请到桌面查看安装进程</div>
    </div>
    <div class="dir">
      <h2>iOS 安装指南</h2>
      <p>1. 点击【允许】</p>
      <div class="step1"></div>
      <p>2. 点击【安装】</p>
      <div class="step2"></div>
      <p>3. 【输入密码】</p>
      <div class="step3"></div>
      <p>4. 点击【安装】</p>
      <div class="step4"></div>
    </div>
    <p class="downTxt">版本要求：iOS 9.0 以上</p>
    <div class="btn-box">
<!--      <a-->
<!--        href="https://iosapp88.oss-cn-chengdu.aliyuncs.com/embedded.mobileprovision"-->
<!--        class="btn-download"-->
<!--      >立即安装</a>-->
      <div class="down-reminder">请到桌面查看安装进程</div>
    </div>
  </div>
</template>

<script>
function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

import "../assets/css/style.css";
import Config from "./config";

export default {
  name: "HelloWorld",
  data() {
    return {
      code: getQueryVariable("code"),
      isShow: false
    };
  },
  computed: {
    ob() {
      return Config[this.code] || { img: ''}
    }
  },
  created() {

  },
  methods: {
    download() {}
  }
};
</script>

<style scoped>
.banner {
  height: 2.1em;
}
.main-logo {
  text-align: center;
  top: 130px;
}
.main-logo img {
  border-radius: 10px;
}
.downTxt {
  margin-top: 120px;
}
.banner-txt-wrap h2 {
  bottom: -60px !important;
}
.banner-txt-wrap h3 {
  bottom: -90px;
}

</style>
