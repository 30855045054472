<template>
    <div id="app">
        <Down/>
    </div>
</template>

<script>
	import Down from './components/Down.vue'

	export default {
		name: 'App',
		components: {
      Down
		}
	}
</script>

<style>

</style>
